<template>
  <div class="text-center">
    <v-dialog
      v-model="localDialog"
      persistent
      width="500"
      content-class="calculadora-dialog"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-if="false"
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        ></v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2 text-break">
          {{
            !titulo
              ? typeError.code == "errorResponseEmpty"
                ? $t("alert.errorNoResultHead")
                : $t("alert.genericTitleError")
              : titulo
          }}
        </v-card-title>

        <v-card-text v-if="typeError">
          <template v-if="typeError.code == 'errorTamEstampacion'">
            <h4>
              {{
                $t("alert.errorTamEstampacionHead", {
                  size: $t("Zonas." + formatZona(typeError.zone.Zona))
                })
              }}
            </h4>
            {{
              $t("alert.errorTamEstampacionBody", {
                talla:
                  typeError.tamEstampacion +
                  (typeError.tamEstX && typeError.tamEstY
                    ? " (" + typeError.tamEstX + "x" + typeError.tamEstY + "mm)"
                    : "cm2")
              })
            }}
            <br />
            <template v-if="typeError.listProduct?.length">
              <h4 class="mt-4">
              <u>{{ $t("alert.errorTamEstampacionListHead") }}</u>
            </h4>
            <ul>
              <li
                v-for="talla in typeError.listProduct"
                :key="talla.Descripcion"
              >
                {{ talla.Descripcion }} ({{ talla.Ancho }}x{{ talla.Alto }}mm)
              </li>
            </ul>
            </template>
          </template>
          <template v-if="typeError.code == 'errorMinUnit'">
            <h4>
              {{
                $t("alert.errorMinUnitTitleHead", {
                  minUnit: typeError.minUnit
                }) +
                (typeError.productCode ? ` (${typeError.productCode})` : "")
              }}
            </h4>
          </template>
          <template v-if="typeError.code == 'errorModelNotFound'">
            <h4>{{ $t("alert.errorModelNotFound") }}</h4>
          </template>
          <template v-if="typeError.code == 'errorNoMoq'">
            <h4>
              {{
                $t("alert.errorMoq", {
                  moq: typeError.minUnit,
                  productCode: typeError.productCode
                    ? ` (${typeError.productCode})`
                    : ""
                })
              }}
            </h4>
          </template>
          <template v-if="typeError.code == 'maxPrice'">
            <h4 class="mt-4">{{ $t("alert.alertMaxPrice") }}</h4>
          </template>
          <template v-if="typeError.code == 'errorResponseEmpty'">
            <h4 class="mt-4">{{ $t("alert.errorNoResultBody") }}</h4>
          </template>
          <template v-if="typeError.code == 'errorMin'">
            <h4 class="mt-4">
              {{ $t("alert.errorMinTecnica", { minUnit: typeError.minUnit }) }}
            </h4>
          </template>
          <template v-if="typeError.code == 'errorColorBanned'">
            <h4 class="mt-4">
              {{ $t("alert.errorColorBanned", { color: typeError.product }) }}
            </h4>
          </template>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cerrar">
            {{ botonCerrar }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
      cargando: false
    },
    titulo: {
      type: String,
      default: ""
    },
    cuerpo: {
      type: String,
      default: ""
    },
    botonCerrar: {
      type: String,
      default: ""
    },
    typeError: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      localDialog: this.dialog
    };
  },
  methods: {
    ...mapActions("modelo", ["clearAlert"]),
    cerrar: function () {
      this.localDialog = false;
      this.$emit("cerrar");
    },
    formatZona(value) {
      return value.toUpperCase().replace(/_/g, " ");
    }
  },
  watch: {
    dialog(newVal) {
      if (newVal != this.localDialog) this.localDialog = newVal;
    },
    localDialog(newVal) {
      if (newVal != this.dialog) {
        this.clearAlert();
        this.$emit("cerrar");
      }
    }
  }
};
</script>
