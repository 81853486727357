import axios from "axios";

// Función para recuperar un carrito
export const GetCart = ({ comercialToken, reprocesosKey, type }) => {
    if ((!comercialToken && !reprocesosKey) || !type) return Promise.reject("GetCart: Missing parameters")

    // Si debemos pedir un comercial, tenemos que tener comercialToken, userLogin y clientId 
    const headers = {
        ReprocesosKey: reprocesosKey,
        ...(comercialToken && { ComercialSession: comercialToken })
    }

    // En caso contrario, pedimos el carrito con el reprocesosKey
    return axios
        .get(`${process.env.VUE_APP_EXTRANET_URL}/Reprocesos/Cart`, {
            params: {
                Type: type
            },
            headers
        })
};

// Función para añadir al carrito
export const AddToCart = ({ type, payload, reprocesosKey, comercialToken }) => {
    if (!type || !payload) return Promise.reject("AddToCart: Missing parameters")

    const headers = {
        ReprocesosKey: reprocesosKey,
        ...(comercialToken && { ComercialSession: comercialToken })
    }

    return axios
        .post(`${process.env.VUE_APP_EXTRANET_URL}/Reprocesos/Cart`, payload, {
            params: {
                Type: type
            },
            headers
        })
}

// Función para borrar una línea del carrito
export const DeleteLineFromCart = ({ lineId, comercialToken, reprocesosKey, type }) => {
    if (!lineId) return Promise.reject("DeleteLineFromCart: Missing parameters")

    const headers = {
        ReprocesosKey: reprocesosKey,
        ...(comercialToken && { ComercialSession: comercialToken })
    }

    return axios
        .delete(`${process.env.VUE_APP_EXTRANET_URL}/Reprocesos/Cart`, {
            params: {
                Id: lineId,
                Type: type
            },
            headers
        })
}

// Función para borrar por completo el carrito
export const DeleteCart = ({ comercialToken, reprocesosKey, type }) => {
    const headers = {
        ReprocesosKey: reprocesosKey,
        ...(comercialToken && { ComercialSession: comercialToken })
    }

    return axios
        .delete(`${process.env.VUE_APP_EXTRANET_URL}/Reprocesos/Cart/All`, {
            params: {
                Type: type
            },
            headers
        })
}

// Función para imprimir una línea del carrito
export const PrintReproceso = ({ lineId, comercialToken, reprocesosKey, lang }) => {
    if (!lineId || (!comercialToken && !reprocesosKey)) return Promise.reject("PrintReproceso: Missing parameters")

    if (comercialToken) {
        return axios.get(`${process.env.VUE_APP_EXTRANET_URL}/Reprocesos/Print/Comercial`,
            {
                params: {
                    id: lineId,
                    lang
                },
                headers: {
                    ComercialSession: comercialToken
                },
                responseType: 'blob',
                'Content-Type': 'application/pdf'
            })
    }

    return axios.get(`${process.env.VUE_APP_EXTRANET_URL}/Reprocesos/Print`,
        {
            params: {
                Id: lineId,
                lang
            },
            headers: {
                ReprocesosKey: reprocesosKey
            },
            responseType: 'blob',
            'Content-Type': 'application/pdf'
        })
}

export const PrintAllCart = ({ comercialToken, reprocesosKey, lang, type, payload = null }) => {
    if (!comercialToken && !reprocesosKey) return Promise.reject("PrintAllCart: Missing parameters")

    const headers = {
        ReprocesosKey: reprocesosKey,
        ...(comercialToken && { ComercialSession: comercialToken })
    }
    let body = new FormData();
    let modification = JSON.stringify(payload);
    body.append('Type', type);
    body.append('ModificationForm', modification);

    return axios.post(
        `${process.env.VUE_APP_EXTRANET_URL}/Reprocesos/Print/Multiples`,
        body,
        {
            params: {
                lang
            },
            headers,
            responseType: 'blob',
            'Content-Type': 'application/pdf'
        }
    );
}

export const PrintIncompleteReproceso = ({ payload, reprocesosKey, lang }) => {
    if (!payload || !reprocesosKey) return Promise.reject("PrintIncompleteReproceso: Missing parameters")

    return axios
        .post(
            `${process.env.VUE_APP_EXTRANET_URL}/Reprocesos/Print`,
            payload,
            {
                params: {
                    lang
                },
                headers: {
                    ReprocesosKey: reprocesosKey
                },
                responseType: 'blob',
                'Content-Type': 'multipart/form-data'
            }
        )
}

// Función para finalizar un carrito
export const FinishCart = ({ reprocesosKey, comercialToken, type, payload }) => {
    if (!reprocesosKey) return Promise.reject("FinishCart: Missing parameters")

    const headers = {
        ReprocesosKey: reprocesosKey,
        ...(comercialToken && { ComercialSession: comercialToken })
    }

    return axios
        .post(`${process.env.VUE_APP_EXTRANET_URL}/Reprocesos/Cart/Finish`, payload, {
            params: {
                Type: type
            },
            headers
        })
}




//* Funciones relacionadas con los comerciales para la intranet

// Función para loguear un comercial
export const LoginComercial = ({ payload, comercialToken }) => {
    if (!payload && !comercialToken) return Promise.reject("LoginComercial: Missing parameters")

    if (payload) {
        return axios
            .post(`${process.env.VUE_APP_EXTRANET_URL}/Comercial/Auth`, payload)
    }

    return axios
        .get(`${process.env.VUE_APP_EXTRANET_URL}/Comercial/Auth`, {
            headers: {
                ComercialSession: comercialToken
            }
        })
}

// Función para buscar un usuario
export const SearchUserList = ({ search, showAllClient, comercialToken }) => {
    if (!search || !comercialToken) return Promise.reject("SearchUserList: Missing parameters")

    return axios
        .get(`${process.env.VUE_APP_EXTRANET_URL}/Comercial/UserList`, {
            params: {
                search,
                showAllClient
            },
            headers: {
                ComercialSession: comercialToken
            }
        })
}

// Función para comerciales, puedan logearse como clientes
export const LoginClient = ({ clientId, comercialToken }) => {
    if (!clientId || !comercialToken) return Promise.reject("LoginClient: Missing parameters")

    return axios
        .get(`${process.env.VUE_APP_EXTRANET_URL}/Comercial/Client/${clientId}`, {
            headers: {
                ComercialSession: comercialToken
            }
        })
}





//* Funciones relacionadas con youReprocesos
export const GetYouReprocesos = ({ id, company }) => {
    if (!id) return Promise.reject("GetYouReprocesos: Missing parameters")

    return axios
        .get(`${process.env.VUE_APP_EXTRANET_URL}/YouReprocesos/${id}`, {
            params: {
                company
            }
        })
}