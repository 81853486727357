<template>
  <div
    v-if="!readOnly"
    :class="`input-product ${wrapperClass} ${disabled ? 'disabled-input' : ''} ${loading ? 'loading' : ''}`"
  >
    <v-btn
      v-if="!hidePlusMinusButtons"
      class="quantity-minus"
      icon
      @click="minusQty"
    >
      <v-icon size="14">mdi-minus</v-icon>
    </v-btn>
    <input
      v-bind="$attrs"
      ref="input"
      :value="internalValue"
      class="input-number"
      :class="inputClasses"
      :label="inputLabel ?? $t('general.Cantidad')"
      type="number"
      :step="dynamicStep"
      :data-min="dynamicMin"
      :min="internalValue > dynamicMin ? 0 : dynamicMin"
      :disabled="disabled"
      @change="debounceUpdateInternalValue($event.target.value)"
      @input="debounceUpdateInternalValue($event.target.value)"
    />
    <v-btn
      v-if="!hidePlusMinusButtons"
      class="quantity-plus"
      icon
      @click="plusQty"
    >
      <v-icon size="14">mdi-plus</v-icon>
    </v-btn>
  </div>
  <div v-else>
    {{ internalValue }}
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: null
    },
    product: {
      type: Object,
      required: true,
      validator: function (value) {
        return value && value.CODE;
      }
    },
    inputClasses: {
      type: [String, Array, Object],
      default: ""
    },
    wrapperClass: {
      type: [String, Array, Object],
      default: ""
    },
    inputLabel: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    hidePlusMinusButtons: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      internalValue: 0,
      hasError: false,
      debounceChange: false
    };
  },
  computed: {
    // Calculamos el step que debemos poner
    dynamicStep() {
      if (this.product && this.product.STEP) {
        return this.product.STEP;
      }

      return 1;
    },
    // Calculamos el min que debemos poner
    dynamicMin() {
      if (this.product && this.product.MOQ) {
        return this.product.MOQ;
      }

      return 0;
    }
  },
  watch: {
    value(newValue) {
      if (newValue !== this.internalValue) this.internalValue = newValue;
    }
  },
  mounted() {
    this.internalValue = this.value ?? 0;
  },
  methods: {
    handleKeyUp(event) {
      this.$emit("keyup-event", event);
    },
    plusQty: function () {
      let actualQty = Number(this.internalValue);
      if (!this.disabled) {
        if (actualQty == 0) {
          this.updateInternalValue(this.dynamicMin);
        } else {
          this.updateInternalValue(actualQty + this.dynamicStep);
        }
      }
    },
    minusQty: function () {
      let actualQty = Number(this.internalValue);

      if (this.disabled) return;

      if (actualQty > this.dynamicMin) {
        this.updateInternalValue(actualQty - this.dynamicStep);
      } else {
        this.updateInternalValue(0);
      }
    },
    async debounceUpdateInternalValue(newVal) {
      if (this.debounceChange) {
        clearTimeout(this.debounceChange);
      }

      if (this.internalValue == newVal) {
        return;
      }

      this.debounceChange = setTimeout(() => {
        this.updateInternalValue(newVal);
      }, 450);
    },
    async updateInternalValue(newValue) {
      if (newValue == 0) {
        // console.log("CERO");
        this.internalValue = 0;
      } else if (newValue <= this.dynamicMin) {
        // console.log("MINIMO");
        this.internalValue = this.dynamicMin;
      } else if (newValue === "") {
        // console.log("VACIO");
        this.internalValue = 1;
      } else if (newValue % this.dynamicStep !== 0) {
        // console.log("REDONDEO");
        this.internalValue =
          Math.ceil(newValue / this.dynamicStep) * this.dynamicStep;

        if (newValue != this.internalValue) {
          this.$refs.input.setCustomValidity("");
          this.$refs.input.reportValidity();
          await this.$nextTick();
        }

        let isInputInvalid = this.$refs.input.validity.valid == false;

        if (isInputInvalid && this.$refs.input.validity.stepMismatch) {
          if (newValue == this.dynamicMin) {
            this.$refs.input.setCustomValidity("");
          }
        }
      } else {
        this.internalValue = newValue;
      }

      this.$emit("input", this.internalValue);
    }
  }
};
</script>

<style lang="scss">
@keyframes loading {
  0% {
    border-bottom: 1.5px solid black;
  }
  30% {
    border-bottom: 1.5px solid var(--v-primary-base);
  }
  70% {
    border-bottom: 1.5px solid var(--v-primary-base);
  }
  100% {
    border-bottom: 1.5px solid black;
  }
}

.input-product {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // border-bottom: 1.5px solid black;
  margin-bottom: 1.5px;
  // margin: 10px 0;
  transition: all 0.4s ease;

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  } /* Para Firefox */
  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  &.disabled-input {
    pointer-events: none;
    opacity: 0.5;
  }

  &.loading {
    animation: loading 2s linear infinite;
  }

  &:hover,
  &:active,
  &:focus-within {
    border-bottom: 1.5px solid var(--v-primary-base);
    margin-bottom: 0px;
  }

  input.input-number {
    all: unset;
    height: 100%;
    font-size: 14px !important;
    text-align: center;
    max-width: 50px !important;
  }
}
</style>
