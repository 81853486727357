<template>
  <div
    v-show="activeCanvas3d && active"
    id="canvas3d"
    :key="'canvas-' + modelCode"
    class="w-100"
  ></div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  init3DCanvas,
  loadColor,
  setActiveZone,
  uploadFile,
  searchModelOnApiAndSetActive,
  resetCanvas3d,
  removeFile
} from "@/utils/customDimUtils";

export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    zona: {
      type: Object,
      required: true
    },
    img: {
      type: Object,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      installed: false,
      json: {}, // JSON con la información de la personalización 3D
      activeZone3D: null, // Zona activa en el canvas 3D
      imgInfoCanvas3d: null // Identificador para poder borrar el logo actual del canvas
    };
  },
  computed: {
    ...mapGetters("modelo", [
      "modelCode",
      "idModel3D",
      "hasModel3D",
      "actualColorInfo"
    ]),
    activeCanvas3d() {
      return process.env.VUE_APP_ACTIVE_3D == 'true' && this.modelCode && this.hasModel3D && this.installed;
    },
    hexcodeColorSelected() {
      return this.actualColorInfo?.hexcode;
    }
  },
  watch: {
    async hexcodeColorSelected(newVal) {

      if (this.activeCanvas3d) {
        await this.updateColorCanvas3d(newVal);
      }
    },
    async img(newVal, oldVal) {
      if (oldVal?.id && oldVal?.base64 && oldVal.id != newVal?.id) {
        // Debemos borrar la imagen previamente en el canvas
        if (!this.imgInfoCanvas3d) {
          console.warn("💻 ~ No tenemos un imgInfoCanvas3d para borrar la imagen");
          return;
        }

        await removeFile(this.imgInfoCanvas3d, this.activeZone3D);
        this.imgInfoCanvas3d = null;
      }

      if (this.activeCanvas3d && newVal && newVal.base64) {
        await this.updateImgCanvas3d(newVal.base64);
        // console.error("TODO: Actualizar imagen en canvas 3D");
      }
    },
    installed(newVal, oldVal) {
      if (newVal == oldVal) return;

      if (newVal) {
        this.$emit("show-canvas");
      } else {
        this.$emit("close-canvas");
      }
    },
    async zona(newVal, oldVal) {
      if (newVal?.Posicion == oldVal?.Posicion) return;

      if (!newVal || !newVal?.Posicion) {
        console.warn("💻 ~ Necesitamos un valor de posicion correcto", newVal);
        return;
      }

      if (this.activeCanvas3d) {
        this.activeZone3D = await setActiveZone(newVal);
      }
    },
    async idModel3D(newVal, oldVal) {
      if (newVal == oldVal) return;

      let result = await searchModelOnApiAndSetActive(newVal);

      if (!result) {
        console.warn("💻 ~ No hemos podido cargar el modelo correcto");
        this.disableCanvas3d();
      } else if (!this.installed) {
        await this.load3D();
      }
    }
  },
  async mounted() {
    await this.load3D();
  },
  beforeDestroy() {
    this.disableCanvas3d();
  },
  methods: {
    async load3D() {
      if (!this.hasModel3D) {
        console.warn("💻 ~ No tenemos un modelo 3D para cargar");
        return;
      }

      if (!this.installed && this.idModel3D) {
        try {
          await init3DCanvas(this.json, this.idModel3D);
        } catch (err) {
          console.error(err);
        }
        this.installed = true;

        await this.updateColorCanvas3d();
      }
    },
    async updateColorCanvas3d(hexcodeColorSelected = null) {
      if (!hexcodeColorSelected) {
        hexcodeColorSelected = this.hexcodeColorSelected;
      }

      await loadColor(hexcodeColorSelected);

      setTimeout(async () => {
        this.activeZone3D = await setActiveZone(this.zona);
      }, 1000);
    },
    async updateImgCanvas3d(base64) {
      if (!this.activeZone3D) {
        console.warn(
          "💻 ~ No hemos podido cargar la zona correcta para el canvas 3d"
        );
        return;
      }
      this.imgInfoCanvas3d = await uploadFile(base64, this.activeZone3D);
    },
    disableCanvas3d() {
      this.installed = false;
      

      // Eliminamos todos los elementos dentro del div canvas3d
      let canvas3d = document.getElementById("canvas3d");
      if (canvas3d) {
        while (canvas3d.firstChild) {
          canvas3d.removeChild(canvas3d.firstChild);
        }
      }

      resetCanvas3d();
    }
  }
};
</script>

<style lang="scss">
#canvas3d {
  max-height: 785px;
  height: 100%;
}
</style>
