// Definición de técnica
class Tecnica {
    constructor() {
        this.isProveedor = true;
        this.nombreTecnica = "Tecnica";
        this.valueTecnica = "Tecnica";
        this.groupTecnica = "";

        //* Genéricos
        // Check para las prendas de pack uno
        this.esPrendaPackUno = false;
        // Tamaño del estampado
        this.tamano = false;
        // Ancho seleccionado en los inputs
        this.maxWidth = false;
        // Alto seleccionado en los inputs
        this.maxHeight = false;
        // Fondo especificado
        this.fondo = false;
        // Planchado
        this.esPlanchado = false;
        // Colores
        this.colores = false;
        // Tipo de tinta
        this.idTipoTinta = false;
        // Número de reprocesos
        this.nreprocesos = false;
        this.noShowHeightAndWidth = false;




        //* BORDADO
        // Número de puntadas
        this.puntadas = false;

        //* DTF
        // Indicación de metros lineales
        this.MetrosLineales = false;

        //* DTF Números
        // Tamaño estampado DTF
        this.tamanoEstampadoNumerosDTF = false;
        this.disenoEspecial = false;

        //* Impresión directa
        // Identificador tamaño impresión directa
        this.idTamImpresion = false;
        this.coloresImprDir = false;

        //* Manipulación
        // Listado de manipulación
        this.listManipulados = false;

        //* Transfer etiquetas
        // Check de imprimir talla
        this.imprimirTalla = false;
        // Check de imprimir diseño
        this.imprimirDiseno = false;

        //* Planchado
        this.listPlanchado = false;

        //* Serigrafía Roly
        this.tamanoEstampado = false;
        this.prendasBlanco = false;
        this.prendasClaro = false;
        this.prendasOscuro = false;
        this.tintaNegra = false;
        this.hasPlanchado = false;

        //* Sublimación Roly
        this.caras = false;

        //* Transfer Roly
        this.productos = false;
        this.tintas = false;

        //* Transfer Números
        this.dobleNumero = false;
        this.typeColor = false;
        this.numColores = false;

        //* Sublimación STM
        this.tamanoCm2 = false;

        //* Digital
        this.tamanocm2Digital = false;

        //* Doming
        this.tamanoCm2Doming = false;

        //* SerigrafiaStm
        // this.tipo = false;
        this.dobleBaseSerigrafia = false;
        this.gajos = false;

        //* Termografía
        this.typeTermograbado = false;

        //* Transfer Digital STM
        this.nombreANombre = false;

        //* Transfer Serigrafico STM
        this.fondoOscuro = false;
    }

    getAvalibleInputs() {
        const propiedadesVerdaderas = [];
        for (const propiedad in this) {
            if (this[propiedad] === true) {
                propiedadesVerdaderas.push(propiedad);
            }
        }
        return propiedadesVerdaderas;
    }
}

// Bordado Roly
class Bordado extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "BORDADOS";
        this.valueTecnica = "Bordados";

        this.puntadas = true;
        this.esPrendaPackUno = true;
    }
}

// DTF Roly
class DTF extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "TRANSFER DIGITAL (DTF)";
        this.valueTecnica = "DTF";

        this.tamano = true;
        this.esPrendaPackUno = true;
        this.MetrosLineales = true;
    }
}

// Números DTF
class DTFNumeros extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "NUMEROS-DTF";
        this.valueTecnica = "DTFNumeros";
        this.groupTecnica = "NÚMEROS"

        this.tamanoEstampadoNumerosDTF = true;
        this.esPrendaPackUno = true;
        this.maxWidth = true;
        this.maxHeight = true;
        this.noShowHeightAndWidth = true;
        this.disenoEspecial = true;
        this.dobleNumero = true;
    }
}


// Impresión directa
class ImpresionDirecta extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "IMPRESIÓN DIRECTA";
        this.valueTecnica = "Impresion";

        this.esPrendaPackUno = true;
        this.idTamImpresion = true;
        this.fondo = true;
        this.coloresImprDir = true;
    }
}


// Impresión vinilo
class ImpresionVinilo extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "IMPRESIÓN VINILO";
        this.valueTecnica = "ImpresionVinilo";

        this.esPrendaPackUno = true;
        this.esPlanchado = true;
        this.idTamImpresion = true;
        this.maxWidth = true;
        this.maxHeight = true;
    }
}

// Manipulados
class Manipulados extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "MANIPULADOS";
        this.valueTecnica = "Manipulados";

        this.esPrendaPackUno = true;
        this.listManipulados = true;
    }
}

// Etiquetas transfer
class TransferEtiquetas extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "Etiquetas Transfer";
        this.valueTecnica = "TransferEtiquetas";
        this.groupTecnica = "ETIQUETAS"

        this.tamano = true;
        this.colores = true;
        this.idTipoTinta = true;
        this.imprimirTalla = true;
        this.imprimirDiseno = true;
    }
}

// DTF etiquetas
class DTFEtiquetas extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "Etiquetas Dtf";
        this.valueTecnica = "DTFEtiquetas";
        this.groupTecnica = "ETIQUETAS"

        this.tamano = true;
        this.colores = true;
        this.idTipoTinta = true;
    }
}

// Planchado
class Planchado extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "PLANCHADO";
        this.valueTecnica = "Planchado";

        this.listPlanchado = true;
    }
}

// Serigrafía Roly
class Serigrafía extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "SERIGRAFÍA";
        this.valueTecnica = "Serigrafia";

        this.prendasBlanco = true;
        this.prendasClaro = true;
        this.prendasOscuro = true;
        this.esPrendaPackUno = true;
        this.tintaNegra = true;
        this.tamano = true;
        this.maxWidth = true;
        this.maxHeight = true;
        this.idTipoTinta = true;
        this.nreprocesos = true;
        this.hasPlanchado = true;
        this.colores = true;
    }
}

// Sublimación Roly
class SublimacionParcial extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "SUBLIMACIÓN";
        this.valueTecnica = "SublimacionParcial";

        this.tamano = true;
        this.maxWidth = true;
        this.maxHeight = true;
        this.esPrendaPackUno = true;
    }
}

class SublmacionTotal extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "SUBLIMACIÓN";
        this.valueTecnica = "SublimacionTotal";

        this.caras = true;
        this.esPrendaPackUno = true;
    }
}

class TransferTintas extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "TRANSFER";
        this.valueTecnica = "TransferTintas";

        this.colores = true;
        this.idTipoTinta = true;
        this.esPrendaPackUno = true;
        this.tamano = true;
        this.maxWidth = true;
        this.maxHeight = true;
        this.tintas = true;
        this.productos = true;
        this.nreprocesos = true;
    }
}

class TransferNumeros extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "NUMEROS-TRANSFER";
        this.valueTecnica = "TransferNumeros";
        this.groupTecnica = "NÚMEROS"

        this.esPrendaPackUno = true;
        this.typeColor = true;
        this.maxHeightSelect = true;
        this.noShowHeightAndWidth = true;
        this.colores = true;
        this.dobleNumero = true;
    }
}

class SublimacionStm extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "SUBLIMACIÓN";
        this.valueTecnica = "SublimacionStm";

        this.tamanoCm2 = true;

        this.nombreANombre = true;
    }
}

class SublimacionStmIndividualLanyard extends SublimacionStm {
    constructor() {
        super();
        this.valueTecnica = "SublimacionStmIndividualLanyard";

        this.caras = true;
        this.tamanoCm2 = false;
        this.nombreANombre = false;
    }
}

class SublimacionStmSombreros extends SublimacionStm {
    constructor() {
        super();
        this.valueTecnica = "SublimacionStmSombreros";

        this.tamanoCm2 = false;
        this.nombreANombre = false;
    }
}

class SublimacionStmTazas extends SublimacionStm {
    constructor() {
        super();
        this.valueTecnica = "SublimacionStmTazas";

        this.specificCaras = true;

        this.tamanoCm2 = false;
    }
}

class Digital extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "DIGITAL";
        this.valueTecnica = "Digital";

        this.tamanocm2Digital = true;
    }
}

class DigitalTextil extends Digital {
    constructor() {
        super();
        this.valueTecnica = "DigitalTextil";

        this.tamanocm2Digital = true;
    }
}

class DomingStm extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "DOMING";
        this.valueTecnica = "DomingStm";

        this.tamanoCm2Doming = true;
    }
}

class BordadoStm extends Bordado {
    constructor() {
        super();
        this.valueTecnica = "BordadoStm";

        this.cortahilos = true;
        this.esPrendaPackUno = false;
    }
}

class SerigrafiaStm extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "SERIGRAFÍA";
        this.valueTecnica = "SerigrafiaStm";

        // this.tipo = true;
        this.dobleBaseSerigrafia = true;
        // this.idTipoTinta = true;
        this.colores = true;
    }
}

class SerigrafiaTarifaTextil extends SerigrafiaStm {
    constructor() {
        super();
        this.valueTecnica = "SerigrafiaTarifaTextil";

        this.tamano = true;
        this.maxWidth = true;
        this.maxHeight = true;
        this.fondoOscuro = true;
    }
}

class SerigrafiaStmGajos extends SerigrafiaStm {
    constructor() {
        super();
        this.valueTecnica = "SerigrafiaStmGajos";

        this.gajos = true;
        this.colores = false;
        this.dobleBaseSerigrafia = false;
    }
}

class SerigrafiaStmSombreros extends SerigrafiaStm {
    constructor() {
        super();
        this.valueTecnica = "SerigrafiaStmSombreros";

        this.dobleBaseSerigrafia = false;
    }
}

class Tampografia extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "TAMPOGRAFÍA";
        this.valueTecnica = "Tampografia";

        // this.tipo = true;
        this.colores = true;
        this.idTipoTinta = true;
    }
}

class CuatricomiaStm extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "CUATRICOMIA";
        this.valueTecnica = "CuatricomiaStm";

    }
}

class Laser extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "LASER";
        this.valueTecnica = "Laser";

    }
}

class Termografia extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "TERMOGRABADO";
        this.valueTecnica = "Termografia";

        this.typeTermograbado = true;
    }
}

class TransferDigitalStm extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "TRANSFER DIGITAL (DTF)";
        this.valueTecnica = "TransferDigitalStm";
        this.groupTecnica = "TRANSFER"

        // this.maxHeight = true;
        // this.maxWidth = true;
        this.tamanoCm2 = true;
        this.nombreANombre = true;
        this.isTransferDigital = true;
    }
}

class TransferDigitalCalzado extends Tecnica {
    constructor() {
        super();
        this.valueTecnica = "TransferDigitalCalzado";

        // this.nombreANombre = true;
        this.isTransferDigital = true;
    }
}

class TransferDigitalStmGajos extends TransferDigitalStm {
    constructor() {
        super();
        this.valueTecnica = "TransferDigitalStmGajos";

        this.gajos = true;
    }
}

class TransferSerigraficoStm extends Tecnica {
    constructor() {
        super();
        this.nombreTecnica = "TRANSFER SERIGRAFICO";
        this.valueTecnica = "TransferSerigraficoStm";
        this.groupTecnica = "TRANSFER"

        this.colores = true;
        this.fondoOscuro = true;
        this.isTransferSerigrafico = true;
    }
}

class TransferSerigraficoTextil extends TransferSerigraficoStm {
    constructor() {
        super();
        this.valueTecnica = "TransferSerigraficoTextil";

        this.tamanoCm2 = true;
    }
}

class TransferSerigraficoStmGajos extends TransferSerigraficoStm {
    constructor() {
        super();
        this.valueTecnica = "TransferSerigraficoStmGajos";

        this.gajos = true;
    }
}

const configuracionTecnicas = {
    Bordado: new Bordado(),
    DTF: new DTF(),
    DTFNumeros: new DTFNumeros(),
    ImpresionDirecta: new ImpresionDirecta(),
    ImpresionVinilo: new ImpresionVinilo(),
    Manipulados: new Manipulados(),
    TransferEtiquetas: new TransferEtiquetas(),
    DTFEtiquetas: new DTFEtiquetas(),
    Planchado: new Planchado(),
    Serigrafía: new Serigrafía(),
    SublimacionParcial: new SublimacionParcial(),
    SublmacionTotal: new SublmacionTotal(),
    TransferTintas: new TransferTintas(),
    TransferNumeros: new TransferNumeros(),

    SublimacionStm: new SublimacionStm(),
    SublimacionStmIndividualLanyard: new SublimacionStmIndividualLanyard(),
    SublimacionStmSombreros: new SublimacionStmSombreros(),
    SublimacionStmTazas: new SublimacionStmTazas(),

    Digital: new Digital(),
    DigitalTextil: new DigitalTextil(),
    DomingStm: new DomingStm(),
    BordadoStm: new BordadoStm(),

    SerigrafiaStm: new SerigrafiaStm(),
    SerigrafiaStmGajos: new SerigrafiaStmGajos(),
    SerigrafiaStmSombreros: new SerigrafiaStmSombreros(),
    SerigrafiaTarifaTextil: new SerigrafiaTarifaTextil(),
    Tampografia: new Tampografia(),

    CuatricomiaStm: new CuatricomiaStm(),
    Laser: new Laser(),
    Termografia: new Termografia(),
    TransferDigitalStm: new TransferDigitalStm(),
    TransferSerigraficoStm: new TransferSerigraficoStm(),
    TransferSerigraficoStmGajos: new TransferSerigraficoStmGajos(),
    TransferSerigraficoTextil: new TransferSerigraficoTextil(),
    TransferDigitalStmGajos: new TransferDigitalStmGajos(),
    TransferDigitalCalzado: new TransferDigitalCalzado()

};

const selectCorrectSublimacion = (isStm, tieneSublimacionTotal, isSublimacionSombreros, isSublimacionTazas, isLanyardSublimacion) => {
    if (!isStm) {
        if (!tieneSublimacionTotal)
            return new SublimacionParcial()
        else
            return new SublmacionTotal()
    }
    else {
        if (isSublimacionSombreros) {
            return new SublimacionStmSombreros()
        }
        else if (isSublimacionTazas) {
            return new SublimacionStmTazas()
        }
        else if (isLanyardSublimacion) {
            return new SublimacionStmIndividualLanyard()
        }
        else {
            // Caso general o caso tazas
            return new SublimacionStm()
        }
    }
}

const tecnicasListCreator = (tecnicasZona, isStm, config) => {
    console.log("🚀 ~ tecnicasListCreator ~ tecnicasZona:", tecnicasZona)
    let tecnicas = [];

    let {
        soloBlancoEnCarritoSublimacion, // Indica si se ha de forzar la condición de solo blanco en el carrito
        tieneSublimacionTotal, // Indica si tiene sublimación total o parcial
        isSublimacionSombreros, // Indica si la sublimación tiene que ser de sombreros o no
        isSublimacionTazas, // Indica si se tiene que usar sublimación tazas
        isLanyardSublimacion, // Incia si se tiene que usar lanyard (sublimación por caras o no)
        useGajosSerigrafia, // indica que la serigrafía se va usar mediante gajos
        useGajosTransfer, // indica que transfer se va usar mediante gajos
        isSerigrafiaSombreros, // indica que se debe usar serigrafia sombreros
        tecnicasTransfer, // Listado de tipos de transfer admitidos para transfer stm,
        useDigitalTextil, // Indica si se va a usar digital textil
        useCalzadoTransfer, // Indica si es un calzado,
        useTransferTextil, // Indica si se va a usar transfer textil
        useSerigrafiaTextil // Indica si usar serigrafía textil con tarifas
    } = config;

    // Si nos fuerzan a que solo haya blanco en el carrito
    if (soloBlancoEnCarritoSublimacion) {
        if (tecnicasZona.Sublimacion) {
            tecnicas.push(selectCorrectSublimacion(isStm, tieneSublimacionTotal, isSublimacionSombreros, isSublimacionTazas, isLanyardSublimacion))
        }

        // Se admite también laser, en caso de que solo se permita sublimación
        if (tecnicasZona.Laser) {
            tecnicas.push(new Laser())
        }

        return tecnicas;
    }

    // Selector serigrafía
    if (tecnicasZona.Serigrafia) {
        if (!isStm) {
            tecnicas.push(new Serigrafía())
        }
        else {
            if (useGajosSerigrafia) {
                tecnicas.push(new SerigrafiaStmGajos())
            }
            else if (isSerigrafiaSombreros) {
                tecnicas.push(new SerigrafiaStmSombreros())
            }
            else if (useSerigrafiaTextil) {
                tecnicas.push(new SerigrafiaTarifaTextil())
            }
            else {
                tecnicas.push(new SerigrafiaStm())
            }
        }
    }


    // Selector Transfer
    if (tecnicasZona.Transfer) {
        // TODO: Añadir funcionalidad recomendado?

        if (!isStm) {
            tecnicas.push(new TransferTintas())
            tecnicas.push(new TransferNumeros())
            // tecnicas.push(new TransferEtiquetas())
        }
        else {
            tecnicasTransfer?.forEach(t => {
                if (t == 'TransferSerigraficoStm') {
                    if (useGajosTransfer) {
                        tecnicas.push(new TransferSerigraficoStmGajos())
                    }
                    else {
                        if (useTransferTextil) {
                            tecnicas.push(new TransferSerigraficoTextil())
                        }
                        else {

                            tecnicas.push(new TransferSerigraficoStm());
                        }
                    }
                }
                else if (t == 'TransferDigitalStm') {
                    if (useCalzadoTransfer) {
                        tecnicas.push(new TransferDigitalCalzado())
                    }
                    else if (useGajosTransfer) {
                        tecnicas.push(new TransferDigitalStmGajos())
                    }
                    else {
                        tecnicas.push(new TransferDigitalStm())
                    }

                }
            })
        }
    }

    if (tecnicasZona.TransferEspeciales) {
        console.warn("NOT IMPLEMENTED: TransferEspeciales")
    }

    if (tecnicasZona.ImpresionVinilica) {
        tecnicas.push(new ImpresionVinilo())
    }

    if (tecnicasZona.Sublimacion) {
        tecnicas.push(selectCorrectSublimacion(isStm, tieneSublimacionTotal, isSublimacionSombreros, isSublimacionTazas, isLanyardSublimacion))
    }

    if (tecnicasZona.ImpresionDirecta) {
        tecnicas.push(new ImpresionDirecta())
    }

    if (tecnicasZona.Bordado) {
        if (!isStm) {
            tecnicas.push(new Bordado())
        }
        else {
            tecnicas.push(new BordadoStm())
        }
    }

    if (tecnicasZona.SerigrafiaNonWoven) {
        console.warn("NOT IMPLEMENTED: SerigrafiaNonWoven")
    }

    if (tecnicasZona.Digital) {
        if (useDigitalTextil) {
            tecnicas.push(new DigitalTextil())
        }
        else {
            tecnicas.push(new Digital())
        }
    }

    if (tecnicasZona.Laser) {
        tecnicas.push(new Laser())
    }

    if (tecnicasZona.Dft) {
        tecnicas.push(new DTF())
        tecnicas.push(new DTFNumeros())
        // tecnicas.push(new DTFEtiquetas())
    }

    if (tecnicasZona.Tampografia) {
        tecnicas.push(new Tampografia())
    }

    if (tecnicasZona.Termograbado) {
        tecnicas.push(new Termografia())
    }

    if (tecnicasZona.Cuatricomia) {
        tecnicas.push(new CuatricomiaStm())
    }

    if (tecnicasZona.Doming) {
        tecnicas.push(new DomingStm())
    }

    return tecnicas;
}

export default {
    configuracionTecnicas,
    getTecnica: function (tecnica) {
        return configuracionTecnicas[tecnica];
    },
    tecnicasListCreator
}