<template>
  <div class="force-height-zone-grid">
    <template v-if="totalUnidades > 0">
      <div class="zones-grid">
        <v-tabs v-model="selectedZone" center-active hide-slider show-arrows>
          <v-tab v-for="zone in activesZones" :key="zone.id" :tab-value="zone.id" :disabled="loadingDesign">
           <span v-if="false">{{ $t("Zonas." + formatZona(zone.Zona)) }} <template v-if="isStaminaModel && false">- {{ formatDescription(zone.Posicion) }}</template></span>
            <div v-else class="d-flex flex-column align-center">
              <span>{{ $t("Zonas." + formatZona(zone.Zona)) }}</span>
              <span v-if="isStaminaModel && false" class="little-description">{{ formatDescription(zone.Posicion) }}</span>
            </div>
           <span></span>
          </v-tab>
          <cotizador-btn
            v-if="numZonas < numMaximunZones"
            class="add-zone-btn"
            content-class="h-100"
            :animated="numZonas == 0"
            :on="{ click: addZoneDialog }"
            :disabled="loadingDesign"
          >
            <span v-if="numZonas == 0">{{ $t('general.Añadir zona') }}</span><v-icon>$fi-plus</v-icon>
          </cotizador-btn>
        </v-tabs>
        <v-tabs-items v-model="selectedZone" class="mh-100 overflow-y-auto pb-5">
          <v-tab-item
            v-for="(zone) in activesZones"
            :key="zone.id"
            :value="zone.id"
            background-color="darkZones"
          >
            <div class="zone-configuration">
                <v-divider></v-divider>
                <GenericGlobalCalculadora :zona="zone" @removeZone="removeZone(zone.id)" />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </template>
    <template v-else>
      <v-col cols="12" class="d-flex justify-center align-center loading-zonas">
        <v-progress-circular
          :size="100"
          :width="7"
          class="ml-5"
          indeterminate
          color="primary"
        >
          <v-icon size="72">mdi-calculator</v-icon>
        </v-progress-circular>

        <div class="ml-5">
          <b>{{ $t("alert.alertSelectProduct") }}</b>
        </div>
      </v-col>
    </template>
    <zones-selector v-model="localeAddZoneDialog" @add-zone="addZone"></zones-selector>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import zonesSelector from "@/components/cotizadorClientes/zonesSelector.vue";
import GenericGlobalCalculadora from "@/components/tecnicas/GenericGlobalCalculadora.vue";
import CotizadorBtn from "@/components/basic/CotizadorBtn.vue";

export default {
  components: {
    zonesSelector,
    GenericGlobalCalculadora,
    CotizadorBtn
  },
  props: {
    initZones: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      numZonas: 0,
      activesZones: [],
      selectedZone: null,
      localeAddZoneDialog: false,
      selectedNewZone: null,
      numZonesPrincipal: 2,
      esRepeticion: false
    };
  },
  computed: {
    ...mapGetters("modelo", ["zonas", "modelCode", "numMaximunZones", "zonasDescription", "isStaminaModel"]),
    ...mapGetters("carrito", ["totalUnidades", "loadingDesign"])
  },
  watch: {
    // zonas() {
    // console.log("Watcher zonas");  this.initZonas();
    // },
    selectedZone(newVal, oldVal) {
      if (newVal == oldVal) return;
      this.$emit("updateSelectedZone", this.activesZones.find(z => z.id == newVal));
    },
    modelCode(newVal) {
      if (newVal) {
        this.activesZones = [];
        this.numZonas = 0;
        this.initZonas();
      }
    },
    totalUnidades(newVal) {
      if (newVal > 0 && this.numZonas == 0 && this.initZones) {
        this.initZonas();
      }
    },
    initZones(newVal) {
      if (this.totalUnidades > 0 && this.numZonas == 0 && newVal) {
        this.initZonas();
      }
    },
    localeAddZoneDialog(newVal) {
      if (!newVal) {
        this.selectedNewZone = null;
        this.$emit("close-dialog");
      }
    }
  },
  mounted() {
    this.initZonas();
  },
  methods: {
    // Funcion para iniciarlizar el conjunto de zonas
    initZonas() {
      if (!this.zonas || this.zonas.length == 0 || this.totalUnidades <= 0) return;

      // this.addZone(this.zonas[0]);
      this.addZoneDialog();

      // this.numZonas = 1;
    },
    // Método para abrir el dialogo de añadir zona
    addZoneDialog() {
      this.localeAddZoneDialog = true;
    },
    // Método para formatear una descripción de zona
     formatDescription(value) {
      return this.zonasDescription.find(z => z.Posicion == value)?.Descripcion ?? "";
    },
    // Método para formatear una zona
      formatZona(value) {
          if (!value) return value;
      return value.toUpperCase().replace(/_/g, " ");
    },
    // Método para añadir una zona
    addZone(selectedZone) {
      let generatedId = Math.floor(Math.random() * 2147483647)
      this.activesZones.push({...selectedZone, id: generatedId });
        this.numZonas = this.activesZones.length;


        this.selectedZone = generatedId;
        this.selectedNewZone = null;
        this.localeAddZoneDialog = false;
    },
    // Método para eliminar una zona
    removeZone(id) {
      this.activesZones = this.activesZones.filter(zone => zone.id !== id);

      this.numZonas = this.activesZones.length;

      this.localeAddZoneDialog = false;
    },

  }
};
</script>

<style lang="scss">


.zones-grid {
  .little-description {
    color: var(--Color-Grey-600, #787878);
    font-family: $heading-font-family;
    font-size: 10px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }
  
  .v-tabs-bar__content {
    background-color: white !important;
  }

  .v-tab.v-tab--active,
  .zone-configuration {
    background-color: var(--v-darkZones-darken1) !important;
  }

  .v-tab {
    background-color: var(--v-darkZones-base) !important;
    color: black !important;
    font: normal normal 500 12px/15px $heading-font-family;
    letter-spacing: 0px;
    border: 2px solid white;
  }

  .v-tab.v-tab--active {
    border: 0px;
  }

  .btt-layout .cotizador-btn .v-icon {
    opacity: .3;
  }
  
}
</style>
