<template>
  <div>
    <v-dialog v-model="localDialog" persistent max-width="500px">
      <v-card
:loading="loading ? 'primary' : false" elevation="4"
        outlined>
        <v-card-title  class="headline">{{
          $t("login.TitleLoginModal")
        }}</v-card-title>

        <v-card-text>
          <div class="d-flex flex-column align-center">
            <span class="mt-3">{{ $t("login.NoKeyAdded") }}</span>
            <span class="mt-3">{{ $t("login.AskForComercial") }}</span>
            <!-- <LoginLink @login="cerrar" class="mt-3" /> -->
          </div>
        </v-card-text>
        <v-alert :value="alert" type="error" class="mx-5" transition="scale-transition">{{ alert }}</v-alert>

        <div class="mx-5">
          <v-text-field v-model="email" :loading="loading" :disabled="loading" outlined color="primary" type="email" :label="$t('login.User')" @keyup.enter="login"></v-text-field>
          <v-text-field v-model="password" :loading="loading" :disabled="loading" outlined color="primary" :type="showPass ? 'text' : 'password'" :label="$t('login.Password')" :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" @keyup.enter="login" @click:append="showPass = !showPass"></v-text-field>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="primary" dark @click="login">
            {{ $t('login.TitleLoginModal') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import LoginLink from "@/components/basic/LoginLink.vue";
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    // LoginLink,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localDialog: this.dialog,
      showPass: false,
      loading: false,
      email: '',
      password: '',
      alert: false,
    };
  },
  computed: {
    ...mapGetters("config", ["comercialInfo"]),
  },
  watch: {
    dialog(newVal) {
      if (newVal != this.localDialog) this.localDialog = newVal;
    },
    localDialog(newVal) {
      if (newVal != this.dialog) {
        this.$emit("cerrar");
      }
    },
  },
  async created() {
    this.loading = true;
    // Si en el local storage tenemos authToken-calculator, intentamos logearnos
    if (localStorage.getItem("authToken-calculator")) {
      var result = await this.tryLoginWithToken();
      if (result) this.cerrar();
    }
    this.loading = false;
  },
  beforeMount() {
    this.clearInputs();
  },
 
  methods: {
    ...mapActions("config", ["loginComercial", "tryLoginWithToken"]),
    cerrar: function () {
      this.clearInputs();
      this.localDialog = false;
      this.$emit("cerrar");
    },
    clearInputs: function () {
      this.showPass = false;
      this.email = '';
      this.password = '';
    },
    login: async function ()
    {
      this.loading = true;
      if (!this.email)
      {
        this.alert = this.$t("login.EmailEmpty");
      }
      else if (!this.password)
      {
        this.alert = this.$t("login.PasswordEmpty");
      }
      else {
        let result = null;
        try {
          result = await this.loginComercial(
            {
              User: this.email,
              Password: this.password
            }
          );
        }
        catch (error) { 
          console.error("Not login");
        }

        if (result)
        {
          this.cerrar();
        }
        else {
          this.alert = this.$t("login.ErrorLogin");
        }
      }

      if(this.alert) setTimeout(() => {
          this.alert = false;
        }, 5000);
      this.loading = false;
    }
  },
  
};
</script>